// React
import React from 'react';
import { useTranslation } from 'react-i18next';

//App
import Spiner from '../Spiner';
import Dropdown from '../Dropdown';

//Interface

import { iUser } from '../type';
import { iInterview } from '../type';
import { slice } from '../../utils';


interface InterviewListProps {
    role: string;
    selected: string | null;
    processName: string;
    interviwsId: string | undefined;
    isLoadingProcess: boolean;
    processes: iInterview[];
    users: iUser
    handleClick: (id: string, user: iUser) => void;
    handleClickNonMappedElement: () => void;
    updateselection: (id: string) => void;
    deleteInterviews: (id: string) => void;
}

const InterviewList: React.FC<InterviewListProps> = ({
    role,
    selected,
    processName,
    interviwsId,
    isLoadingProcess,
    processes,
    users,
    handleClick,
    handleClickNonMappedElement,
    updateselection,

    deleteInterviews,
}) => {
    const { t } = useTranslation();
    function checkADuplicate(
        name: string,
        role: string,
        email?: string,
        interviews?: Array<iInterview>
    ): string {

        if (!name) {
            return email ? slice(email, 17) + `(invited)` : '(invited)';
        }
        if (interviews) {
            let duplicateName = interviews.filter(
                (interview) => interview.collaboration.user.fullname === name
            );


            if (duplicateName.length > 1) {
                return slice(name, 16) + `(${slice(role, 7)})`;
            }
        }
        return slice(name, 23);
    }

    return (
        <div className="mt-3 w-100">
            {role !== 'WORKER' ? (
                <button
                    data-bs-target="#inviteUser"
                    data-bs-toggle="modal"
                    className="text-decoration-none text-light fs-6 bg-dark border-0 rounded-4 mb-2"
                >
                    <div className="rounded-4 py-1 ps-1 btn btn-secondary bg-dark border-0">
                        <span>
                            <i className="fa-solid fa-plus pe-0"></i>
                        </span>
                        <span className="ms-1">{t('views.processInterview.addCollaborators')}</span>
                    </div>
                </button>
            ) : (
                <div className="text-decoration-none text-light fs-6 bg-dark border-0 rounded-4 mb-2">
                    <div className="rounded-4 py-1 ps-1 bg-dark border-0">
                        <span className="ms-1">{t('views.processInterview.collaborators')}</span>
                    </div>
                </div>
            )}

            <div className="overflow-auto customHeightInterview w-100">
                {processName && (
                    <div onClick={() => handleClick(interviwsId ? interviwsId : "", users)}>
                        <h5
                            onClick={handleClickNonMappedElement}
                            className={`text-light text-decoration-none w-100 btn ${selected === null ? 'btn-secondary btn-md' : 'btn-outline-secondary btn-md'
                                } border-0 rounded-4 fs-6`}
                        >
                            <div className="d-flex">
                                <>
                                    <span>
                                        <i className="fa-solid fa-user me-2"></i>
                                    </span>
                                    {processName.length > 23 ? `${processName.slice(0, 23)}...` : processName} (Owner)
                                </>

                            </div>
                        </h5>
                    </div>
                )}

                {isLoadingProcess ? (
                    <Spiner className="spinner-border-sm" />
                ) : (
                    <>
                        {processes.map((interview: iInterview) => {
                            const fullname = interview.collaboration.user.fullname;
                            const email = interview.collaboration.user?.email;
                            const id = interview._id;
                            return (
                                <div
                                    onClick={() => {
                                        handleClick(interview._id, interview.collaboration.user);
                                        updateselection(interview._id);
                                    }}
                                    key={interview._id}
                                    title={`Role: ${interview.role}`}
                                    className={`interview-item fs-6 mb-0 text-light w-100 text-decoration-none btn ${selected === interview._id ? 'btn-secondary btn-md' : 'btn-outline-secondary btn-md'
                                        } border-0 rounded-4`}
                                >
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex text-nowrap">
                                            <i className="fa-solid fa-user me-2"></i>
                                            <span>{checkADuplicate(fullname, interview?.role || "", email, processes)}</span>
                                        </div>
                                        {role !== 'WORKER' && (
                                            <div className="" onClick={(e) => e.stopPropagation()}>
                                                <Dropdown
                                                    dropName=""
                                                    dropdownType="btn-group dropend"
                                                    iconClassName="fa fa-ellipsis-vertical small dropdown_icon"
                                                    buttonDropClassName="bg-transparent border-0 ms-2"
                                                    styleButton={{}}
                                                    deleteProcess={() => deleteInterviews(id)}
                                                    action1={t('words.delete')}
                                                    theRouteOfAction4=""
                                                    theRouteOfAction5=""
                                                    dataBsTarget=""
                                                    routeFaq=""
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </>
                )}
            </div>
        </div>
    );
};

export default InterviewList;
