//React
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

//DHTMLX
import fromCDN from "from-cdn";
import Button from "../button/Button";

//App
import { FamlaServices } from "../../../services";
import { useAppSelector } from "../../../stores";
import { DefaultsSettingForShapesType, swimlaneDefaultsType, DiagramToolbarConfig } from "../../../constants";

import { Images } from "../../../constants";
import { ConfirmationModal } from "../../../views/modal";
import { Dropdown } from "../../../components";


const DefaultEditorSwimlane = ({ swimlaneExport, id, allSwimLane, sId, t, approvallist, loadAllSwimlane }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [diagram, setDiagram] = useState(null);
  const role = useAppSelector((state) => state.role.role);
  const [editor, setEditor] = useState(null);
  const users = useAppSelector((state) => state.auth);
  const closeModal = useRef();
  const inputRef = useRef();
  const inputRefa = useRef();
  const inputRefdisa = useRef();
  const [zoomLevel, setZoomLevel] = useState(1);
  const navigate = useNavigate();
  let lastSwimlaneName = allSwimLane[allSwimLane.length - 1]?.name
  const [state, setState] = useState({
    submit: false,
    swimlaneName: lastSwimlaneName,
    comment: "",
    isDisabled: true,
  });

  const [alert, setAlert] = useState({
    type: '',
    msg: ""
  });


  const handleSubmit = async (swimlaneId, status) => {
    setState({ ...state, submit: true });
    let newForm = {
      validation: status === "approve" ? true : false,
      comment: state.comment,
      updated_at: Date.now()
    }
    let data = await FamlaServices("api_host", `/approval/${swimlaneId}`, "PUT", newForm, users.access_token);

    if (data.status === 200) {
      let messageType = status === "approve" ? t('features.swimlane.sucessApprov') : t('features.swimlane.sucessDisapprov')
      setAlert({ type: 'success', msg: messageType });
      setState((prevState) => ({ ...prevState, submit: false, comment: "" }));
      setTimeout(() => {
        setAlert({ type: 'danger', msg: "" });
      }, 5000);

    }
    else if (data.status === 400) {
      setAlert({ type: 'danger', msg: data.body.description });
      setState((prevState) => ({ ...prevState, submit: false, comment: "" }));
      setTimeout(() => {
        setAlert({ type: '', msg: "" });
      }, 5000);


    } else if (data.status === 401) {
      setAlert({ type: 'danger', msg: data.body.description });
      setState((prevState) => ({ ...prevState, submit: false, comment: "" }));
      setTimeout(() => {
        setAlert({ type: '', msg: "" });
      }, 5000);

    }
    else {
      setAlert({ type: 'danger', msg: data.body.description });
      setState((prevState) => ({ ...prevState, submit: false, comment: "" }));
      setTimeout(() => {
        setAlert({ type: '', msg: "" });
      }, 5000);
    }

  };


  const handleChange = (event) => {
    const { type, name, value, checked } = event.target;
    let fieldValue = type === 'checkbox' ? checked : value;

    if (type === 'number') {
      fieldValue = Number(fieldValue);
    }

    if (name === 'swimlane') {
      const isSameName = lastSwimlaneName === fieldValue;
      setState({ ...state, swimlaneName: fieldValue, isDisabled: isSameName });
    }

    if (name === 'comment') {
      setState({ ...state, comment: fieldValue })
    }

  };

  function activeFocus() {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }
  function activeFocusa() {
    if (inputRefa.current) {
      inputRefa.current.focus();
    }
  }
  function activeFocusdisa() {
    if (inputRefdisa.current) {
      inputRefdisa.current.focus();
    }
  }



  const template = ({ img, text, ip }) => {
    return `
      <section class="dhx-diagram-demo_network-card">
        <img src="${img}" alt="${text}"></img>
        <span>${text}</span>
        <span>${ip}</span>
      </section>
    `;
  };

  useEffect(() => {
    let isMounted = true;

    fromCDN([
      "https://webix.io/dev/dhtmlx/diagram/diagram_6.0/codebase/diagramWithEditor.css",
      "https://webix.io/dev/dhtmlx/diagram/diagram_6.0/codebase/diagramWithEditor.js",
    ]).then(() => {
      if (!isMounted) return;

      // eslint-disable-next-line no-undef
      const diagramInstance = new dhx.Diagram("diagram", {
        type: "default",
        defaults: swimlaneDefaultsType,
      });

      // eslint-disable-next-line no-undef
      const editorInstance = new dhx.DiagramEditor("editor", {
        type: "default",
        defaults: DefaultsSettingForShapesType,
        controls: { autoLayout: false },
        peBarWidth: 320,
        lineGap: 20,
        view: DiagramToolbarConfig

      });


      editorInstance.diagram.addShape("networkCard", {
        template,

        properties: [
          { type: "arrange" },
          { type: "img", label: "Photo" },
          { type: "text" },
          { type: "text", label: "IP", property: "ip" },
        ],
      });

      editorInstance.events.on("ApplyButton", applyButton);
      editorInstance.events.on("ResetButton", resetButton);

      diagramInstance.data.parse(swimlaneExport);

      setDiagram(diagramInstance);
      setEditor(editorInstance);

    });

    return () => {
      isMounted = false;
      diagram && diagram.destructor();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleGoBack = () => {
    navigate(-1);
  };

  const runEditor = () => {
    setCollapsed(false);
    editor.import(diagram);
  };

  const applyButton = async () => {
    setState({ ...state, submit: true })

    if (state.swimlaneName.trim().length === 0) {
      setAlert({ type: 'warning', msg: t('features.swimlane.swimlaneNameIsNandatory') })
      setState({ ...state, submit: false })
      return
    }

    diagram.data.parse(editor.serialize())
    const swimlaneData = editor.serialize()

    let data = await FamlaServices("api_host", `/mapping/${id}?name=${state.swimlaneName}`, "PUT", swimlaneData, users.access_token);
    if (data.status === 200) {
      loadAllSwimlane()
      closeModal.current && closeModal.current.click();
      setCollapsed(true);
      setState({ ...state, submit: false, swimlaneName: "" })
    } else if (data.body.status_code === 400) {
      setAlert({ type: 'warning', msg: t('features.swimlane.yourChangesWereNotSaved') })
      setState({ ...state, submit: false, swimlaneName: "" })
    } else {
      setAlert({ type: 'warning', msg: t('navigation.anErrorOcured') })
      setState({ ...state, submit: false, swimlaneName: "" })
    }
  };

  const resetButton = () => {
    setCollapsed(true);
  };


  const zoomIn = () => {
    const zoomableElements = document.getElementsByClassName('dhx_diagram__scale-container');
    if (zoomableElements.length > 0) {
      const zoomableElement = zoomableElements[0];
      const currentZoom = parseFloat(zoomableElement.style.zoom) || 1;
      const newZoom = currentZoom + 0.1;
      zoomableElement.style.zoom = newZoom;
      setZoomLevel(newZoom.toFixed(1));
    }
  };

  const zoomOut = () => {
    const zoomableElements = document.getElementsByClassName('dhx_diagram__scale-container');
    if (zoomableElements.length > 0) {
      const zoomableElement = zoomableElements[0];
      const currentZoom = parseFloat(zoomableElement.style.zoom) || 1;
      const newZoom = Math.max(currentZoom - 0.1, 0.1);
      zoomableElement.style.zoom = newZoom;
      setZoomLevel(newZoom.toFixed(1));
    }
  };

  const exportToPDF = () => {
    diagram.export.pdf({
      name: " Generated by Famla.pdf",
      header: "<h1>Diagram generated by Famla</h1>",
      footer: "<h2>Generated by Famla</h2>"
    });
  }

  const exportToPNG = () => {
    diagram.export.png({
      name: " Generated by Famla.png",
      header: "<h1>Diagram generated by Famla</h1>",
      footer: "<h2>Generated by Famla</h2>"
    });
  }

  const css = collapsed
    ? "dhx-container_inner dhx_sample-container__without-editor"
    : "dhx-container_inner dhx_sample-container__with-editor";

  return (
    <>
      <div className={css}>
        <div className="container-fluid bg-white">
          <div className="dhx_sample-controls d-flex justify-content-between">
            <div onClick={handleGoBack} style={{ cursor: "pointer" }}>
              <img width={120} className="img-fluid" src={Images.famla} alt="famla" />
            </div>

            <div className=" d-flex w-75 justify-content-end">
              {collapsed ?
                <>
                  <>
                    <span onClick={zoomOut} className=" fs-5 bg-white text-black " style={{ cursor: "pointer" }}>{'-'}</span>
                    <span className="dhx_sample-btn dhx_sample-btn--flat bg-dark text-white mx-2 ">{zoomLevel}</span>
                    <span onClick={zoomIn} className="border border-0  fs-5 bg-white text-black me-2" style={{ cursor: "pointer" }}>{'+'}</span>
                  </>
                  <Dropdown
                    dropName={t('words.file')}
                    dropdownType={"dropstart"}
                    buttonDropClassName="dhx_sample-btn dhx_sample-btn--flat bg-dark"
                    activeFocusdisa={activeFocusdisa}
                    activeFocusa={activeFocusa}
                    backToHome={handleGoBack}
                    close={t('words.close')}
                    approve={approvallist.length !== 0 && t('words.approve')}
                    disapprove={approvallist.length !== 0 && t('words.disApprove')}
                    editdiadram={role === "ADMIN" && t('words.edit')}
                    runEditor={runEditor}
                    theRouteOfAction5={''}
                    theRouteOfAction4={''}
                    routeFaq={''}
                    exportt={true}
                    exportToPDF={exportToPDF}
                    exportToPNG={exportToPNG}
                  />
                  < div data-bs-target={'#mapModal'} data-bs-toggle='modal'>
                    <Button key={3} className="dhx_sample-btn dhx_sample-btn--flat border-0 bg-dark " name={t('features.swimlane.versionHistory')} />
                  </div>
                </>
                :
                <>
                  <Button key={4} className="dhx_sample-btn dhx_sample-btn--flat border-0 bg-dark " name={t('words.cancel')} onClick={resetButton} />
                  < div data-bs-target={'#renameSwimlane'} data-bs-toggle='modal'>
                    <Button key={5} className="dhx_sample-btn dhx_sample-btn--flat border-0 bg-dark " name={t('words.save')} onClick={activeFocus} />
                  </div>
                </>
              }
            </div>
          </div>
        </div>
        <div className="dhx_sample-widget" id="diagram" style={collapsed ? {} : { display: "none" }}></div>
        <div className="dhx_sample-widget" id="editor" style={collapsed ? { display: "none" } : {}}></div>
      </div>
      <>
        <ConfirmationModal
          key={1}
          id="approve"
          title={t('words.approve')}
          message={t('features.swimlane.approveQuestion')}
          buttonLabel={t('words.approve')}
          inputLabel={t('words.comments')}
          inputName="comment"
          inputValue={state.comment}
          inputRef={inputRefa}
          handleChange={handleChange}
          handleAction={() => handleSubmit(sId, "approve")}
          submit={state.submit}
          alert={alert}
          closeModalRef={closeModal}
        />
        <ConfirmationModal
          key={2}
          id="disapprove"
          title={t('words.disApprove')}
          message={t('features.swimlane.disApproveQuestion')}
          buttonLabel={t('words.disApprove')}
          inputLabel={t('words.comments')}
          inputName="comment"
          inputValue={state.comment}
          inputRef={inputRefdisa}
          handleChange={handleChange}
          handleAction={() => handleSubmit(sId, "disapprove")}
          submit={state.submit}
          alert={alert}
          closeModalRef={closeModal}
        />
        <ConfirmationModal
          key={3}
          id="renameSwimlane"
          title="Save file as"
          buttonLabel="Save"
          inputLabel="Diagram name"
          inputName="swimlane"
          inputValue={state.swimlaneName}
          inputRef={inputRef}
          handleChange={handleChange}
          handleAction={applyButton}
          submit={state.submit}
          alert={alert}
          closeModalRef={closeModal}
          showInput={true}
          isDisabled={state?.isDisabled}
        />
      </>
    </>
  );
};

export default DefaultEditorSwimlane;

