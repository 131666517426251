// React Component
import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// App Component
import { AppLayout, HeaderLayout, ProcessLayout } from '../../layouts'
import { FamlaServices } from '../../services'
import { useAppSelector } from '../../stores'
import { ProcessList, SearchInput } from '../../components'


// interface
import { iProject } from '../../interfaces'
import { useSearch } from '../../utils'
interface StateType {
    isLoadingProcess: boolean;
    process: any[];
    project: iProject;
    isLoadingProject: boolean
}


const ProcessByFolderView = () => {
    const { id } = useParams();
    const users = useAppSelector((state) => state.auth);
    const { t } = useTranslation();
    const [state, setState] = React.useState<StateType>({
        isLoadingProcess: true,
        isLoadingProject: true,
        process: [],
        project: {} as iProject,
    })

    const { searchQuery, setSearchQuery, searchResults, searchLoad, setFilter } = useSearch(`/process/search/by/project/${id}`);
    const options = [
        { label: "role", value: "ROLE" }
    ]
    React.useEffect(() => {

        const loadProcessByFolder = async () => {

            try {
                let data = await FamlaServices("api_host", `/process/project/${id}`, "GET", "", users.access_token);
                if (data.status === 200) {
                    setState((prev) => ({ ...prev, process: data.body.data, isLoadingProcess: false }));
                } else {
                    setState((prev) => ({ ...prev, isLoadingProcess: false }));
                }
            } catch (error: any) {
                setState((prev) => ({ ...prev, isLoadingProcess: false }));
            }
        }

        loadProcessByFolder();

        const loadProjectById = async () => {

            try {
                let data = await FamlaServices("api_host", `/project/by/id/${id}`, "GET", "", users.access_token);
                if (data.status === 200) {
                    const projects = data.body.data
                    setState((prev) => ({ ...prev, project: projects, isLoadingProject: false }));
                }
            } catch (error: any) {
                setState((prev) => ({ ...prev, error: error.message }));
            }
        }

        loadProjectById();

    }, [id, users.access_token])


    return (
        <AppLayout>
            <div className="container-fluid mt-3 pb-3 px-5 w-100">
                <HeaderLayout loadcompany={false} />
                <div className="mt-4 m-auto d-flex align-items-center justify-content-center w-75">
                    <SearchInput
                        inputType="text"
                        inputValue={searchQuery}
                        placeholderValue={`${t('words.search')}...`}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(event.target.value)}
                        inputContainerClass="w-75"
                        inputStyle={{ borderRadius: 20 }}
                        inputClass="bg-black text-light border-0"
                        iconClass="fa-solid fa-magnifying-glass  "
                        iconName=""
                        formClass='rounded-5 bg-black text-light text-bg-dark'
                        iconContainer='bg-black text-light border-0'
                        filter={true}
                        setFilter={setFilter}
                        fiterOption={options}
                    />
                </div>
                <ProcessLayout
                    title={searchQuery === "" ? `${state.project.title && state.project.title}` : `${t('words.searching')}...${searchQuery}`}
                    arrowBack={state.project.title ? true : false}
                    style={{ height: 'auto', width: "100%" }}
                    addClassName='overflow-auto w-100 controleScroll'
                >
                    <ProcessList
                        isLoading={state.isLoadingProcess}
                        list={state.process}
                        t={t}
                        searchResults={searchResults}
                        searchQuery={searchQuery}
                        searchLoad={searchLoad}
                    />
                </ProcessLayout>

            </div>
        </AppLayout>
    )
}

export default ProcessByFolderView